<template>
    <div class="left_button_box">
        <div class="btns">
            <router-link tag="button" class="btn-1" :to="{path: '/recharge', query: {t: new Date().getTime()}}">
                <img src="../assets/images/icon/cenha/icon-recharge.png"/> 입금
            </router-link>
            <router-link tag="button" class="btn-1" :to="{path: '/exchange', query: {t: new Date().getTime()}}">
                <img src="../assets/images/icon/cenha/icon-exchange.png"/> 출금
            </router-link>
            <router-link tag="button" class="btn-1" :to="{path: '/customer_center', query: {t: new Date().getTime()}}">
                <img src="../assets/images/icon/cenha/icon-qustion.png"/> 고객센터
            </router-link>
            <router-link tag="button" class="btn-1" :to="{path: '/message', query: {t: new Date().getTime()}}">
                <img src="../assets/images/icon/cenha/icon-message.png"/> 쪽지함
            </router-link>
        </div>
        <div class="btns2" style="">
            <router-link tag="button" class="btn-2" :to="{path: '/sports', query: {t: new Date().getTime()}}">스포츠(크로스)
            </router-link>
            <router-link tag="button" class="btn-2 lr" :to="{path: '/sports_inplay', query: {t: new Date().getTime()}}">
                스포츠(인플레이)
            </router-link>
            <router-link tag="button" class="btn-2" :to="{path: '/sports_special', query: {t: new Date().getTime()}}">
                스포츠(스페셜)
            </router-link>
        </div>
        <div class="btns2" style="">
            <router-link tag="button" class="btn-2"
                         :to="{path: '/leisure/eospowerball1m', query: {t: new Date().getTime()}}">미니게임
            </router-link>
            <router-link tag="button" class="btn-2 lr"
                         :to="{path: '/casinohonor', query: {type:1,t: new Date().getTime()}}">카지노
            </router-link>
            <router-link tag="button" class="btn-2"
                         :to="{path: '/casinohonor', query: {type:2,t: new Date().getTime()}}">슬롯
            </router-link>
        </div>
        <div class="btns2" style="">
            <router-link tag="button" class="btn-2" :to="{path: '/sports_betinfo', query: {t: new Date().getTime()}}">
                베팅내역
            </router-link>
            <router-link tag="button" class="btn-2 lr" :to="{path: '/notice', query: {t: new Date().getTime()}}">공지사항
            </router-link>
            <router-link tag="button" class="btn-2" :to="{path: '/event', query: {t: new Date().getTime()}}">이벤트
            </router-link>
        </div>
        <div class="btns2" style="">
            <router-link tag="button" class="btn-2" :to="{path: '/culcheck', query: {t: new Date().getTime()}}">출석부
            </router-link>
            <router-link tag="button" class="btn-2 lr" :to="{path: '/coupon', query: {t: new Date().getTime()}}">쿠폰
            </router-link>
            <router-link tag="button" class="btn-2" :to="{path: '/customer_center', query: {t: new Date().getTime()}}">고객센터
            </router-link>
        </div>
    </div>
</template>

<script>
    import {INIT_USERINFO, RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../store/mutation-types";
    import {changePoint, logout} from "../network/userRequest";

    export default {
        name: "LeftButtonsComp",
        methods: {
            initUserInfo() {
                this.$store.dispatch('actionUserInfo')
            },
            changePoint() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                changePoint().then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                    if (!res.data.success) {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    } else {
                        this.initUserInfo()
                    }
                })
            },
            // logout() {
            //     this.$swal({
            //         title: '로그아웃 하시겠습니까 ?',
            //         type: 'question',
            //         confirmButtonText: '로그아웃',
            //     }).then((res) => {
            //         if (res.value) {
            //             this.$store.commit(INIT_USERINFO);
            //             window.sessionStorage.clear();
            //             this.$router.replace("/main")
            //             this.$store.state.uncheckedMessageCount = 0;
            //             logout();
            //         }
            //     })
            //
            // },
        }
    }
</script>

<style scoped>
    .left_button_box {
        width: 100%;
        padding: 0;
    }

    .left_button_box .btns {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        padding: 0px 0;
        border-bottom: 1px solid #d9cdb5;
    }

    .left_button_box .btns .btn-1 {
        width: 33.33%;
        height: 34px;
        background-color: var(--leftBarBtnBg);
        color: #e7e7e7;
        margin: 1px 0;
        border-radius: 0px;
        font-weight: bold;
    }

    .left_button_box .btns .btn-1 img {
        width: 20px;
        vertical-align: middle;
    }

    .left_button_box .btns2 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #d9cdb5;
    }

    .left_button_box .btns2 .btn-2 {
        width: 33.33%;
        height: 40px;
        border-bottom: 1px solid #303030;
        color: #353535;
        font-weight: bold;
        font-size: 12px;
    }

    .left_button_box .btns2 .lr {
        border-left: 1px solid #303030;
        border-right: 1px solid #303030;
    }

    @media screen and (max-width: 1024px) {
        .left_button_box {
            --display: none !important;
        }
    }
</style>