<template>

    <!--베팅카트-->
    <div class="bet_cart">
        <div class="cart_head">
            <div class="title">BETTING CART</div>
        </div>
        <div class="cart">
            <div class="sitem" v-for="(item,index) in $store.state.sportsCartItems" :key="item.id">
                <div class="item_name">
                    <!--                    <div class="name">-->
                    <!--                        <span  v-html="item.homeTeamName"></span> <span style="color: deepskyblue">vs</span> <span v-html="item.awayTeamName"></span>-->
                    <!--                    </div>-->
                    <div class="name" v-if="item.isbonus === sportsConst.NOT">
                        {{item.homeTeamName}} <span style="color: deepskyblue">vs</span> {{item.awayTeamName}}
                    </div>
                    <!--                    <div class="name" v-if="item.isbonus === sportsConst.YES" style="font-style: italic;color: #ffa500">-->
                    <!--                        보너스 배당-->
                    <!--                    </div>-->
                    <div class="del" @click="del(index,item)" v-if="item.isbonus == sportsConst.NOT">
                        <i class="fa fa-close"></i>
                    </div>
                </div>
                <div class="item_info">
                    <div class="kind" v-if="item.isbonus === sportsConst.NOT">
                        <span v-if="item.kind ===  sportsConst.GAME_KIND_1X2">1x2</span>
                        <span v-if="item.kind === sportsConst.GAME_KIND_HANDICAP">Handicap</span>
                        <span v-if="item.kind ===  sportsConst.GAME_KIND_OVERUNDER">OverUnder</span>
                    </div>
                    <div class="sinfo">
                        <div class="sn">
                            <div v-if="item.kind === sportsConst.GAME_KIND_1X2">
                                <span v-if="item.selectWay === sportsConst.WAY_HOME"
                                      v-html="'W1 - ' + item.homeTeamName"></span>
                                <span v-if="item.selectWay === sportsConst.WAY_DRAW && item.isbonus === sportsConst.NOT">Draw</span>
                                <span v-if="item.selectWay === sportsConst.WAY_DRAW && item.isbonus === sportsConst.YES"
                                      v-html="'W2 - ' + item.drawTeamName"></span>
                                <span v-if="item.selectWay === sportsConst.WAY_AWAY"
                                      v-html="'W3 - ' + item.awayTeamName"></span>
                            </div>
                            <div v-if="item.kind === sportsConst.GAME_KIND_HANDICAP">
                                <span v-if="item.selectWay === sportsConst.WAY_HOME"
                                      v-html="item.homeTeamName + ' ('+item.handicapVal+')'"></span>
                                <span v-if="item.selectWay === sportsConst.WAY_DRAW">Draw</span>
                                <span v-if="item.selectWay === sportsConst.WAY_AWAY"
                                      v-html="item.awayTeamName +' ('+item.handicapVal * -1+')'"></span>
                            </div>
                            <div v-if="item.kind === sportsConst.GAME_KIND_OVERUNDER">
                                <span v-if="item.selectWay === sportsConst.WAY_HOME">Over ({{item.overunderVal}})</span>
                                <span v-if="item.selectWay === sportsConst.WAY_AWAY">Under ({{item.overunderVal}})</span>
                            </div>

                        </div>
                        <div class="sodd">{{item.selectOdd}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="$store.state.sportsCartItems.length === 0" class="cart_selected_items">
            <div class="item">Choose a match</div>
        </div>
        <div class="info">
            <div class="item">
                <div class="it">
                    <span class="t1">Max Bet</span>
                    <span class="t2" v-if="displayPosition !== sportsConst.GAME_DISPLAYPOSITION_SPECIAL">{{this.rankConfig.betMaxLimitCash|comma}} </span>
                    <span class="t2" v-else>{{this.rankConfig.specBetMaxLimitCash|comma}} </span>
                </div>
                <div class="it">
                    <span class="t1">Maximum Win</span>
                    <span class="t2" v-if="displayPosition !== sportsConst.GAME_DISPLAYPOSITION_SPECIAL">{{this.rankConfig.winMaxLimitCash|comma}} </span>
                    <span class="t2" v-else>{{this.rankConfig.specWinMaxLimitCash|comma}} </span>
                </div>
                <div class="it">
                    <span class="t1">Money</span><span class="t2">{{$store.state.userInfo.cash|comma}} </span>
                </div>
                <!--                <div v-if="bonusOdds > 0" class="bg-bonus it">-->
                <!--                    <span class="t1 text-white">보너스 배당</span><span class="t2 text-white">{{bonusOdds}}</span>-->
                <!--                </div>-->
                <!--                <div v-if="bonusCash > 0" class="bg-bonus it">-->
                <!--                    <span class="t1 text-white">보너스 금액</span><span class="t2 text-green02">{{bonusCash|comma}}</span>-->
                <!--                </div>-->
                <div class="it">
                    <span class="t1 text-orange">Odds</span><span class="t2 text-orange">{{totalOdd}}</span>
                </div>
                <div class="it">
                    <span class="t1 text-orange">Winning Amount</span><span
                        class="t2 text-orange">{{totalWinCash|comma}}</span>
                </div>
                <div class="it">
                    <span class="t1 text-orange">Bet Amount</span>
                    <span class="t2">
                                <input type="text" v-model="betCash" @input="setBetCahsComma" style="width: 100px;"/>
                            </span>
                </div>
            </div>
            <div class="money_buttons">
                <button class="" @click="cashAdd(5000)">5,000</button>
                <button class="" @click="cashAdd(10000)">10,000</button>
                <button class="" @click="cashAdd(50000)">50,000</button>
                <button class="" @click="cashAdd(100000)">100,000</button>
                <button class="" @click="cashAdd(500000)">500,000</button>
                <button class="" @click="cashAdd(1000000)">1,000,000</button>
                <button class="" @click="maxCash()">Max Win</button>
                <button class="" @click="allInCash()">All Money</button>
                <button class="" @click="initBetCart()">Reset</button>
                <!--                <button class="" @click="initBetCart()">초기화</button>-->
            </div>
            <div class="btn_bet">
                <button @click="betNow()">Bet Now!</button>
                <!--                <a href="javascript:void(0)" @click="betNow()">-->
                <!--                    <img src="../../assets/images/right/cart/btn-betnow.png" style="width: 100%" alt="">-->
                <!--                </a>-->

            </div>

        </div>
    </div>


</template>

<script>
    import {RECEIVE_SPORTS_CART_ITEM_DEL, RECEIVE_SPORTS_CART_ITEM_DEL_ALL} from "../../store/mutation-types";
    import sportsConst from "../../common/sportsConst";
    import {mapGetters} from 'vuex'
    import {logout} from "../../network/userRequest";
    import {dobet} from "../../network/sportsBetRequest";
    import Clock from "../Clock";
    import {checkLoginMinxin} from "../../common/mixin";

    export default {
        name: "SportsBetCartComp",
        mixins: [checkLoginMinxin],
        components: {Clock},
        props: {
            displayPosition: {
                type: Number,
                default() {
                    return sportsConst.GAME_DISPLAYPOSITION_1x2;
                }
            },
        },
        data() {
            return {
                sportsConst,
                odds: 0,//선택경기 총배당
                winCash: 0,//총당첨금액
                betCash: 0,//베팅금액
                betInfo: {
                    selectedVals: [],
                    betCash: 0
                },
                bonusCash: 0,//보너스 금액
                bonusOdds: 0,//보너스 배당,
                mobileBetCartClose: false,
                locked: false,
                bonusExceptBedang: 1.3,
                crossNeedFolderCount: 2,
            }
        },
        methods: {
            /*배팅카트 아이템 삭제*/
            del(index, item) {
                item.selectWay = sportsConst.WAY_NONE
                item.selectOdd = 1;
                this.$store.commit(RECEIVE_SPORTS_CART_ITEM_DEL, item)
            },
            /*베팅카드 머니 버튼클릭*/
            cashAdd(amount) {
                this.betCash = this.$root.$options.filters.comma(this.getBetCash + parseInt(amount))
            },
            setBetCahsComma() {
                if (this.betCash == '') {
                    this.betCash = ''
                } else {
                    this.betCash = this.$root.$options.filters.comma(this.betCash)
                }
            },
            resetCash() {
                this.betCash = '';
            },
            allInCash() {
                this.betCash = this.$root.$options.filters.comma(this.$store.state.userInfo.cash)
            },
            /*베팅카드 초기화 ,아이템 전체 삭제*/
            initBetCart() {
                this.betCash = ''
                this.$store.commit(RECEIVE_SPORTS_CART_ITEM_DEL_ALL)
            },
            /*상한가 베팅*/
            maxCash() {
                if (this.odds <= 1 || this.cartItems.length === 0) return false;
                if (this.displayPosition === sportsConst.GAME_DISPLAYPOSITION_SPECIAL) {
                    this.betCash = this.$root.$options.filters.comma(Math.floor((this.rankConfig.specWinMaxLimitCash - 3000) / this.odds))
                } else {
                    this.betCash = this.$root.$options.filters.comma(Math.floor((this.rankConfig.winMaxLimitCash - 3000) / this.odds))
                }

            },
            /*베팅하기*/
            betNow() {
                if (!this.checkLogin()) {
                    return false
                }
                //선택경기개수 체크
                if (this.cartItems.length === 0) {
                    this.$swal({
                        //title: '베팅하실 경기를 선택하세요',
                        title: 'Select the match you want to bet on',
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }
                //축구 동일경기 크로스 베팅시
                //선택경기개수 체크
                if (!this.checkCroessBetFolderCount()) {
                    this.$swal({
                        //title: '축구 동일경기 크로스 최소 ' + this.crossNeedFolderCount + '폴더 부터 베팅가능합니다',
                        title: 'You can bet on a minimum of ' + this.crossNeedFolderCount + ' folders on the same soccer match.',
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }

                //단폴더 체크
                if (this.cartItems.length === 1) {
                    if (this.getBetCash > this.rankConfig.betOneMaxLimitCash) {

                        this.$swal({
                            //title: '단폴더 최대 베팅금액 ' + this.$root.$options.filters.comma(this.rankConfig.betOneMaxLimitCash) + ' 원을 초과하였습니다',
                            title: 'The maximum bet amount for a single folder has exceeded ' + this.$root.$options.filters.comma(this.rankConfig.betOneMaxLimitCash) + ' won',
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                        return false;
                    }
                }
                //두폴더 체크
                if (this.cartItems.length === 2) {
                    if (this.getBetCash > this.rankConfig.betTwoMaxLimitCash) {
                        this.$swal({
                            // title: '두폴더 최대 베팅금액 ' + this.$root.$options.filters.comma(this.rankConfig.betTwoMaxLimitCash) + ' 원을 초과하였습니다',
                            title: 'The maximum bet amount for a both folder has exceeded ' + this.$root.$options.filters.comma(this.rankConfig.betOneMaxLimitCash) + ' won',
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                        return false;
                    }
                }
                //최고베당체크
                if (this.odds > this.rankConfig.oddsMaxLimit) {
                    this.$swal({
                        //title: '최대 배당율 ' + this.$root.$options.filters.comma(this.rankConfig.oddsMaxLimit) + ' 배를 초과하였습니다',
                        title: 'Maximum odds of ' + this.$root.$options.filters.comma(this.rankConfig.oddsMaxLimit) + ' exceeded.',
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }
                //최소베팅금액
                if (this.getBetCash < this.rankConfig.betMinLimitCash) {
                    this.$swal({
                        //title: '최소 베팅금액은 ' + this.$root.$options.filters.comma(this.rankConfig.betMinLimitCash) + ' 원입니다',
                        title: 'The minimum bet amount is ' + this.$root.$options.filters.comma(this.rankConfig.betMinLimitCash) + 'won',
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }
                //최대베팅금액
                if (this.getBetCash > this.rankConfig.betMaxLimitCash) {
                    this.$swal({
                        //title: '최대 베팅금액 ' + this.$root.$options.filters.comma(this.rankConfig.betMaxLimitCash) + ' 원을 초과하였습니다',
                        title: 'The maximum bet amount has exceeded ' + this.$root.$options.filters.comma(this.rankConfig.betMaxLimitCash) + 'won',
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }
                //최대당첨금액
                if (this.totalWinCash > this.rankConfig.winMaxLimitCash) {
                    this.$swal({
                        //title: '최대 당첨금액 ' + this.$root.$options.filters.comma(this.rankConfig.winMaxLimitCash) + ' 원을 초과하였습니다',
                        title: 'The maximum winning amount exceeded ' + this.$root.$options.filters.comma(this.rankConfig.winMaxLimitCash) + 'won',
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }

                //베팅시작
                //let betcontent = this.$root.$options.filters.comma(this.getBetCash) + '을 베팅하시겠습니까?';
                let betcontent = ' Would you like to bet ' + this.$root.$options.filters.comma(this.getBetCash) + 'won?';
                if (this.cartItems.length === 1 && this.$store.state.userInfo.rank.betOneWincashPercent > 0) {
                    betcontent = 'When betting on a single folder, -' + this.$store.state.userInfo.rank.betOneWincashPercent + ' will be deducted. Would you like to bet?';
                }
                this.$swal({
                    title: betcontent,
                    type: 'question',
                    confirmButtonText: ' Yes ',
                    cancelButtonText: ' Not '
                }).then((res) => {
                    this.betInfo = {selectedVals: []}
                    if (res.value) {
                        this.cartItems.forEach((item) => {
                            this.betInfo.selectedVals.push(item.id + '-' + item.selectWay + '-' + item.isbonus);
                        })
                        this.betInfo.betCash = this.getBetCash;
                        dobet(this.betInfo).then(res => {
                            if (res.data.success) {
                                this.$swal({
                                    //title: '베팅성공',
                                    title: 'Betting success',

                                    type: 'success',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                                this.$store.commit(RECEIVE_SPORTS_CART_ITEM_DEL_ALL)
                                //베팅성공시 회원정보를 init
                                this.$store.dispatch('actionUserInfo')
                                this.betCash = 0;
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }
                        })
                    }
                })

            },
            /*다폴더 보너스*/
            initBonus() {
                /*보너스*/
                this.bonusCash = 0;
                this.bonusOdds = 0;
                let folderCount = this.cartItems.length;
                let bonusIntotal = 0;
                this.cartItems.map(function (item) {
                    if (item.selectOdd < sportsConst.BONUS_BEDANG_EXCEPT_FLAG) {
                        folderCount--;
                    }
                })
                switch (folderCount) {
                    case 0:
                    case 1:
                        this.bonusOdds = 0;
                        break
                    case 2:
                        if (this.bonusConfig.f2 > 1) {
                            this.bonusOdds = this.bonusConfig.f2;
                        }
                        break;
                    case 3:
                        if (this.bonusConfig.f3 > 1) {
                            this.bonusOdds = this.bonusConfig.f3;
                        }
                        break;
                    case 4:
                        if (this.bonusConfig.f4 > 1) {
                            this.bonusOdds = this.bonusConfig.f4;
                        }
                        break;
                    case 5:
                        if (this.bonusConfig.f5 > 1) {
                            this.bonusOdds = this.bonusConfig.f5;
                        }
                        break;
                    case 6:
                        if (this.bonusConfig.f6 > 1) {
                            this.bonusOdds = this.bonusConfig.f6;
                        }
                        break;
                    case 7:
                        if (this.bonusConfig.f7 > 1) {
                            this.bonusOdds = this.bonusConfig.f7;
                        }
                        break;
                    case 8:
                        if (this.bonusConfig.f8 > 1) {
                            this.bonusOdds = this.bonusConfig.f8;
                        }
                        break;
                    case 9:
                        if (this.bonusConfig.f9 > 1) {
                            this.bonusOdds = this.bonusConfig.f9;
                        }
                        break;
                    default:
                        if (this.bonusConfig.f10 > 1) {
                            this.bonusOdds = this.bonusConfig.f10;
                        }
                        break;
                }
                bonusIntotal = Math.round(this.getBetCash * (this.odds * this.bonusOdds).toFixed(2));
                this.bonusCash = bonusIntotal - this.totalWinCash;
            },

            hideCart() {
                //모바일경우 베팅카드 숨김기능
                //RightBarComp.vue에서 모니터
                this.$bus.$emit('rightMenueHandle', false)
            },
            cartLockAble() {
                this.locked = !this.locked;
                //카트고정여부
                this.$bus.$emit('cartLockAble', this.locked)
            },
            checkCroessBetFolderCount() {
                let selectFolderCount = this.cartItems.length;
                let groupCode = '0'
                let isSoccerCross = false;
                this.cartItems.map(item => {
                    if (item.isbonus === sportsConst.NOT) {
                        if (item.groupCode === groupCode) {
                            isSoccerCross = true
                        }
                        if (item.league.gameTypeId === sportsConst.GAME_TYPE_SOCCER) {
                            groupCode = item.groupCode;
                        }
                    }
                });

                if (!isSoccerCross) return true

                return selectFolderCount >= this.crossNeedFolderCount
            }

        },
        computed: {
            ...mapGetters({
                'cartItems': 'getSportsCartItems',
                'rankConfig': 'getRankConfig',
                'bonusConfig': 'getBonusConfig'
            }),
            /*총배당*/
            totalOdd() {
                return this.odds.toFixed(2)
            },
            /*총당첨*/
            totalWinCash() {
                if (this.betCash != '') {
                    return Math.round(this.totalOdd * this.getBetCash)
                } else {
                    return 0
                }
            },
            /*캐쉬가 String으로 되여 전부 Integer로 전화*/
            getBetCash() {
                if (this.betCash == '') return 0

                return parseInt(this.$root.$options.filters.replace(this.betCash, ',', ''))
            }
        },
        created() {
            this.betCash = ''
        },
        watch: {
            /*카드아이템이 변경될때마다 체크*/
            "cartItems": {
                deep: true,
                handler: function (newValue) {
                    /*총배당을 0으로 설정*/
                    this.odds = 0;
                    newValue.forEach((item) => {
                        if (this.odds === 0) {
                            this.odds = parseFloat(item.selectOdd)
                        } else {
                            this.odds = this.odds * parseFloat(item.selectOdd)
                        }
                    })
                    /*다폴더 보너스 설정*/
                    //this.initBonus()
                }
            },
            betCash() {
                /*다폴더 보너스 설정*/
                //this.initBonus()
            },


        },
    }
</script>

<style scoped>
    .cart .sitem {
        width: 100%;
        height: 100px;
        background-color: var(--betCartSitemBg);
        color: #5c5c5c;
        border: 1px solid var(--betCartSitemBorderColor);
        font-weight: 300;
        font-size: 12px;
        margin-bottom: 5px;
        border-radius: 2px;
    }

    .cart .sitem .item_name {
        width: 100%;
        height: 32px;
        border-bottom: 1px solid #4f4f51;
        display: flex;
        justify-content: space-between;
        color: #dedede;
        font-weight: bold;
    }

    .cart .sitem .item_name .name {
        width: 80%;
        line-height: 32px;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        box-sizing: border-box;
        padding: 0 5px;
    }

    .cart .sitem .item_name .del {
        width: 20%;
        line-height: 32px;
        text-align: center;
        cursor: pointer;
    }

    .cart .sitem .item_info {
        width: 100%;
        height: 40px;
        box-sizing: border-box;
        padding-left: 5px;
    }

    .cart .sitem .kind {
        width: 100%;
        line-height: 32px;
        color: #808080;
        text-align: left;
    }

    .cart .sitem .sinfo {
        width: 100%;
        line-height: 30px;
        display: flex;
        justify-content: space-between;

    }

    .cart .sitem .sinfo .sn {
        width: 80%;
        text-align: left;
        color: #c9c6f6;
    }

    .cart .sitem .sinfo .sodd {
        width: 20%;
        text-align: center;
        color: #c9c6f6;
    }
</style>